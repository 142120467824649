.projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .project-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.75rem;

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .project {
      max-width: 450px;

      .project-description {
        font-size: 0.9rem;
      }

      img.logo {
        width: 48px;
        height: auto;
        border-radius: 100%;
      }

      img.logo-no-round {
        width: 48px;
        height: auto;
      }
    }
  }
}