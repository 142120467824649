.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .all-tech-stack-container {
    max-width: 900px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}