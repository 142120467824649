@import "src/assets/styles/theme";

$bottom-border-thickness: 2px;

.navbar-item {
  border-bottom: $bottom-border-thickness solid $dark;
  font-weight: 500;

  transition: border-bottom-color ease-in 250ms;

  &:hover {
    border-bottom: $bottom-border-thickness solid $primary;
  }

  &.active {
    color: $primary;
    border-bottom: $bottom-border-thickness solid $primary;
  }
}