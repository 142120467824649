$dark: #101111;
$dark-bis: #262727;

$white: #ebe7e7;
$primary: #ffc683;
$secondary: #83bcff;
$warning: #f57729;
$danger: #F14668;
$success: #48C78E;
$link: #00D1B2;
$info: $secondary;

$scheme-main: $dark;

$text: $white;
$card-background-color: $dark-bis;