@import "assets/styles/theme";
@import "assets/styles/fonts";

// --------------- BULMA OVERRIDES ---------------
$navbar-item-hover-background-color: $dark;
$navbar-item-hover-color: $primary;

$button-text-hover-color: $dark;

$card-radius: 0.55rem;
$card-header-color: $white;

$tag-background-color: $dark-bis;
$tag-color: $white;
// ------------------------------------------------

@import "bulma";
@import "bulma-timeline";

body, html {
  width: 100vw;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  @media (max-width: 1022px) {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-bottom: 1.2rem;
  }
}

a {
  color: $secondary;

  &:hover {
    color: darken($secondary, 5%);
  }
}


.w-100 {
  width: 100% !important;
}

.dropdown-divider {
  background-color: $dark-bis;
}

.is-secondary {
  background-color: $secondary;
}

.has-text-secondary {
  color: $secondary;
}

.is-dark-bis {
  background-color: $dark-bis;
}

.has-text-dark-bis {
  color: $dark-bis;
}
