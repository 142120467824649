.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .intro-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .stats-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  .contact-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .contact-section-links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      a {
        font-size: 1.5rem;
        margin: 0 0.5em;

        &.linkedin {
          color: #0077b5;
        }

        &.github {
          color: #ffffff;
        }

        &.gitlab {
          color: #fc6d26;
        }
      }
    }
  }
}