@import "src/assets/styles/theme";
@import "src/assets/styles/fonts";

.button {
  font-family: $family-primary;
  font-weight: 500;
}

.button.is-primary {
  color: $dark;
}