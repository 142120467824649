@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@400;500;700&display=swap');

$family-primary: "Montserrat", sans-serif;
$family-secondary: "Lato", sans-serif;

.primary-font {
  font-family: $family-primary;
}

.secondary-font {
  font-family: $family-secondary;
}
